import * as React from 'react';
import styled from '../theme';
import Layout from '../components/layout';
import Container from '../components/sections/Container';
import Intro from '../components/sections/Intro';
import Section from '../components/sections/Section';
import ContactForm from '../components/molecules/ContactForm';

const PaddingDiv = styled.div`
  padding: 60px 40px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  & > span:not(:first-child) {
    margin-top: 10px;
  }
  @media all and (min-width: 50em) {
    align-items: center;
  }
`;

interface Props {
  history?: History;
  location?: Location;
}

const ContactPage: React.SFC<Props> = ({ location }) => (
  <Layout>
    <Container current={location.pathname.substring(1)}>
      <Intro
        title="reach out"
        subTitle="Want to hire me? Need someone to bring your idea to life?
            Or maybe just ask me a question? Fill in the letter below and I’ll get back to you."
      />
      <Section current={location.pathname.substring(1)} title="">
        <PaddingDiv>
          <ContactForm />
        </PaddingDiv>
      </Section>
    </Container>
  </Layout>
);

export default ContactPage;
