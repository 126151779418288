import * as React from 'react';
import * as anime from 'animejs';
import { Formik, FormikProps, Form, Field, FieldProps } from 'formik';
import styled, { theme, keyframes } from '../../theme';
import { encode } from '../../lib/utils';

const SelectLabel = styled.label`
  position: relative;
  &::after {
    content: "\\21AF";
    font-size: 1.8rem;
    right: .2em;
    top: -1rem;
    padding: 0 0 2px;
    position: absolute;
    pointer-events: none;
    color white;
  }
`;

const Select = styled.select`
  padding: 0.8rem;
  margin: 0;
  background: ${theme.contact};
  color: #fff;
  border: none;
  outline: none;
  display: inline;
  appearance: none;
  cursor: pointer;
  border-radius: 0;
  position: relative;
  padding-right: 2em;
  padding-left: 0.5em;
  margin-left: 0.6ch;
  margin-bottom: 0.4rem;
  line-height: 1;
`;

const Block = styled.p`
  margin-bottom: 10px;
  font-size: 1.4rem;
  @media all and (min-width: 50em) {
    font-size: 1.6rem;
  }
`;

const Input = styled.input`
  border: none;
  border-bottom: 1px solid ${theme.contact};
  color: #fff;
  display: inline-block;
  width: auto;
  background: rgba(0, 0, 0, 0.15);
  padding: 0.4rem;
  border-radius: 0;
  &:focus {
    outline: none;
  }
`;

const TextArea = styled.textarea`
  background: rgba(0, 0, 0, 0.2);
  border: none;
  border-left: 2px solid ${theme.contact};
  width: 270px;
  min-height: 100px;
  padding: 1rem;
  @media all and (min-width: 50em) {
    width: 100%;
    min-height: 150px;
  }
`;

const SubmitContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
`;

const SubmitButton = styled.button`
  position: relative;
  display: flex;
  align-items: center;
  background: ${theme.contact};
  position: relative;
  padding: 16px 20px;
  border: none;
  cursor: pointer;
  outline: none;
  overflow: hidden;
  color: #f8f8f8;
  text-decoration: none !important;
  font-size: 1rem;
  font-weight: 700;
`;

const ArrowContainer = styled.div`
  display: inline-block;
  position: relative;
  margin-left: 30px;
  cursor: pointer;
`;

const bounce = keyframes`
from {
  transform: translateX(0px);
}
to {
  transform: translateX(-15px);
}`;

const ErrorContainer = styled.i`
  margin-left: 15px;
  animation: ${bounce} 800ms cubic-bezier(0.7, 0, 0.3, 1) infinite alternate;
  display: inline-block;
  vertical-align: middle;
  color: red;
`;

const Arrow = styled.i`
  transition: all 500ms cubic-bezier(1, 0, 0, 1) 0ms;
  ${SubmitButton}:hover & {
    opacity: 0;
    transform: translateX(60px);
  }
`;

const ArrowHover = styled.i`
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(-20px);
  transition: all 500ms cubic-bezier(1, 0, 0, 1) 0ms;
  opacity: 0;
  ${SubmitButton}:hover & {
    opacity: 1;
    transition-delay: 50ms;
    transform: translateX(0px);
  }
`;

const ProgressBar = styled.div`
  position: absolute;
  height: 20px;
  width: 0;
  right: 0;
  top: 50%;
  left: 50%;
  border-radius: 200px;
  transform: translateY(-50%) translateX(-50%);
  background: #6c5ce7;
`;

const SVG = styled.svg`
  width: 30px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
  left: 50%;
  right: 0;
`;

const Check = styled.path`
  fill: none;
  stroke: #ffffff;
  stroke-width: 3;
  stroke-linecap: round;
  stroke-linejoin: round;
`;

const HiddenInput = styled.input`
  visibility: hidden;
`;

interface FormValues {
  typeOf: string;
  projectName: string;
  budget: string;
  description: string;
  spamQuestion: string;
  email: string;
  name: string;
  question: string;
}

interface State {
  submitted: boolean;
  typeOf: string;
  error: boolean;
  basicTimeline: anime.AnimeTimelineInstance;
}

export default class ContactForm extends React.Component<any, State> {
  constructor(props: any) {
    super(props);

    this.state = {
      typeOf: 'project',
      submitted: false,
      basicTimeline: null,
      error: false,
    };
  }

  componentDidMount() {
    if (document) {
      const basicTimeline: anime.AnimeTimelineInstance = anime.timeline({
        autoplay: false,
      });

      this.setState({ basicTimeline }, this.timeLine);
    }
  }

  timeLine = () => {
    if (document) {
      const pathEls: SVGElement[] = Array.from(
        document.querySelectorAll('#checkPath')
      );
      const [pathEl] = pathEls;
      let offset = anime.setDashoffset(pathEl);
      pathEls.forEach((p: SVGElement) => {
        offset = anime.setDashoffset(p);
        p.setAttribute('stroke-dashoffset', offset.toString());
      });

      this.state.basicTimeline
        .add({
          targets: '#submitText',
          duration: 1,
          opacity: '0',
        })
        .add({
          targets: '#submitButton',
          duration: 1300,
          height: 20,
          width: 300,
          backgroundColor: '#f8f8f8',
          border: '0',
          borderRadius: 100,
        })
        .add({
          targets: '#progressBar',
          duration: 2000,
          width: 300,
          easing: 'linear',
        })
        .add({
          targets: '#submitButton',
          width: 0,
          height: 0,
          duration: 1,
        })
        .add({
          targets: '#progressBar',
          width: 65,
          height: 65,
          delay: 500,
          duration: 750,
          borderRadius: 80,
          backgroundColor: '#6c5ce7',
        })
        .add({
          targets: pathEls,
          strokeDashoffset: [offset, 0],
          duration: 200,
          easing: 'easeInOutSine',
        });
    }
  };

  handleChange = (e: React.ChangeEvent<HTMLSelectElement>) =>
    this.setState({ typeOf: e.target.value });

  handleSubmit = (values: FormValues) => {
    const spamQuestionIsValid = (answer: string) => {
      if (answer.trim().toLowerCase() === 'blue') {
        return true;
      }
      return false;
    };
    if (!values.email) {
      this.setState({ error: true });
    } else {
      if (spamQuestionIsValid(values.spamQuestion)) {
        this.setState({ error: false });
        this.state.basicTimeline.play();
        if (!this.state.submitted) {
          fetch('/', {
            method: 'POST',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            body: encode({ 'form-name': 'contact', ...values }),
          })
            .then(() => this.setState({ submitted: true }))
            .catch(e => {
              this.state.basicTimeline.restart();
              this.setState({ submitted: false });
            });
        }
      } else {
        this.state.basicTimeline.restart();
        this.setState({ submitted: false });
      }
    }
  };

  render() {
    return (
      <div>
        <Block>Hi Kyle!</Block>
        <Formik
          initialValues={{
            typeOf: 'make something awesome for me',
            projectName: '',
            budget: '$5,000 - $10,000',
            description: '',
            spamQuestion: '',
            email: '',
            name: '',
            question: '',
          }}
          onSubmit={(values: FormValues) => this.handleSubmit(values)}
          render={(formikBag: FormikProps<FormValues>) => (
            <Form
              name="contact"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
            >
              <input type="hidden" name="form-name" value="contact" />
              {!this.state.submitted && (
                <Field
                  name="typeOf"
                  render={({ field, form }: FieldProps<FormValues>) => (
                    <Block>
                      <span>I want you to</span>
                      <SelectLabel>
                        <Select
                          {...field}
                          onChange={e => {
                            field.onChange(e);
                            this.handleChange(e);
                          }}
                        >
                          <option value="project">
                            make something awesome for me
                          </option>
                          <option value="question">answer a question</option>
                        </Select>
                      </SelectLabel>
                    </Block>
                  )}
                />
              )}
              {!this.state.submitted &&
                this.state.typeOf === 'project' && (
                  <Field
                    name="projectName"
                    render={({ field, form }: FieldProps<FormValues>) => (
                      <Block>
                        <span>My project is called </span>
                        <Input
                          type="text"
                          {...field}
                          placeholder="Crazy Project Name!"
                        />{' '}
                        and it's going to be awesome!
                      </Block>
                    )}
                  />
                )}
              {this.state.typeOf === 'project' && (
                <Field
                  name="budget"
                  render={({ field, form }: FieldProps<FormValues>) => (
                    <Block>
                      <span>I've got a budget of</span>
                      <SelectLabel>
                        <Select {...field}>
                          <option>$5,000 - $10,000</option>
                          <option>less then $5,000</option>
                          <option>$10,000 - $15,000</option>
                          <option>more then $15,000</option>
                        </Select>
                      </SelectLabel>
                      <span> to make it happen</span>
                    </Block>
                  )}
                />
              )}
              <Field
                name="description"
                render={({ field, form }: FieldProps<FormValues>) => (
                  <div>
                    <Block>
                      {this.state.typeOf === 'project'
                        ? "Here's a short description of my idea and why I'm excited to make it."
                        : "Here's my question."}
                    </Block>
                    <Block>
                      <TextArea {...field} />
                    </Block>
                  </div>
                )}
              />
              <Field
                name="spamQuestion"
                render={({ field, form }: FieldProps<FormValues>) => (
                  <Block>
                    <span>The color of the sky is </span>
                    <Input
                      type="test"
                      {...field}
                      placeholder="Spam protection"
                    />
                    {this.state.error && (
                      <ErrorContainer className="fa fa-long-arrow-left" />
                    )}
                  </Block>
                )}
              />
              <Field
                name="email"
                render={({ field, form }: FieldProps<FormValues>) => (
                  <Block>
                    <span>Email me at </span>
                    <Input
                      type="email"
                      {...field}
                      placeholder="awesome@person.com"
                    />
                    {this.state.error && (
                      <ErrorContainer className="fa fa-long-arrow-left" />
                    )}
                  </Block>
                )}
              />
              <Field
                name="name"
                render={({ field, form }: FieldProps<FormValues>) => (
                  <Block>
                    <span>Thank you, </span>
                    <Input
                      type="text"
                      {...field}
                      placeholder="Awesome Person"
                    />
                  </Block>
                )}
              />
              <HiddenInput name="bot-field" />
              <SubmitContainer>
                <SubmitButton id="submitButton" type="submit">
                  <span id="submitText">Submit</span>
                  <ArrowContainer>
                    <Arrow className="fa fa-long-arrow-right" />
                    <ArrowHover className="fa fa-long-arrow-right" />
                  </ArrowContainer>
                </SubmitButton>
                <ProgressBar id="progressBar" />
                <SVG x="0px" y="0px" viewBox="0 0 25 30">
                  <Check
                    id="checkPath"
                    className="st0"
                    d="M2,19.2C5.9,23.6,9.4,28,9.4,28L23,2"
                  />
                </SVG>
              </SubmitContainer>
            </Form>
          )}
        />
      </div>
    );
  }
}
